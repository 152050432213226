"use strict";

class ModalWaiting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: props.language.toLowerCase(),
            started: props.started || [false]
        }
    }

    componentDidMount(){
        switch (this.state.language) {
            case "en": {
                $("#modalWaitingTitle").text("Loading...");
                break;
            }
            default: {
                $("#modalWaitingTitle").text("Chargement en cours...");
                break;
            }
        }
        
        if (this.state.started[0]) {
            $('#modalWaiting').modal('show');
        }

        $('#modalWaiting').on('shown.bs.modal', (e) => {
            if (!this.state.started[0]) {
                $('#modalWaiting').modal('hide');
            }
        });
    }

    render() {
        return <div className="modal fade" data-keyboard="false" data-backdrop="static" id="modalWaiting" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex justify-content-around">
                            <h4 id="modalWaitingTitle" className="modal-title">Loading...</h4>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex justify-content-around">
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

ModalWaiting.open = function(language, isModalWaitingStarted) {
    const domContainerModalWaiting = document.querySelector('#modalWaitingContainer');
    ReactDOM.render(
        <ModalWaiting
            key={new Date()}  // Required by React to re-render
            language={language || "fr"}
            started={isModalWaitingStarted}
        >
        </ModalWaiting>,
        domContainerModalWaiting
    );
};

function showModalWaiting(isModalWaitingStarted) {
    setTimeout(function() {
        if (isModalWaitingStarted[0]) {
            const language = $("#language").val();
            ModalWaiting.open(language, isModalWaitingStarted);
        }
    }, 1000);
}

function hideModalWaiting(isModalWaitingStarted) {
    isModalWaitingStarted[0] = false;
    $('#modalWaiting').modal("hide");
}

function prepareModalWaiting() {
    return [true];
}